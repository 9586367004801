"use client";
import { Helmet } from 'react-helmet';
import Footer from "../components/default/Footer";
import LoadingScreen from "../common/loader";
import ProgressScroll from "../components/default/ProgressScroll";
import Header from "../components/default/projects/project-brand-rsg/Header";
import Challenge from "../components/default/projects/project-brand-rsg/Challenge";
import Next from "../components/default/projects/project-brand-rsg/Next";
import Story from "../components/default/projects/project-brand-rsg/Story";
import ArchiveHeaderTrans from '../components/default/ArchiveHeaderTrans';


export default function ProjectBrandRsg() {
  return (
    <>
      <Helmet>
        <title>Archives | Branding RSG</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <LoadingScreen />
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <ArchiveHeaderTrans />
              <Header />
              <Challenge />
              <Story />
              <Next />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
