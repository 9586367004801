"use client";
import React, { useState, /* useEffect, */ useLayoutEffect } from "react";
import { Skeleton } from "@mui/material";
import { gsap } from "gsap";
import data from "../../../data/portfolios/workstand.json";
import initIsotope from "../../../common/initIsotope";
import Lottie from "lottie-react";
import HZLogoAnimation from "../../animation/HZ-Logo-Lottie.json";
import { Link } from "react-router-dom";

function Portfolio() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header .container-xxl",
      { opacity: 1, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => {
      tl.kill();
      setImageLoaded(Array(data.length).fill(false)); // Reset images loaded state
    };
  }, []);

  useLayoutEffect(() => {
    const initGallery = () => initIsotope("gallery");
    // Reinitialize Isotope when coming back to the page.
    initGallery();

    // Cleanup function
    return () => {
      // Clean Isotope if needed or make sure it's reinitialized correctly
    };
  }, []);

  // State to manage image loading status
  const [imageLoaded, setImageLoaded] = useState(
    Array(data.length).fill(true)
  );

  // Function to handle image load
  const handleImageLoad = (index) => {
    setImageLoaded((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });
  };

  console.clear();

  return (
    <section
      className="work-minimal pb-140 main-bg3 pagetop header"
      /* style={{ minHeight: "120vh" }} */
    >
      <div className="container-xxl" style={{ paddingTop: "8rem" }}>
        <div className="gallery row sm-marg">
          {data.map((item, i) => (
            <div
              key={i}
              className={`col-lg-4 col-md-6 mt-10 items ${item.categories
                .map((category) => category.replace(/\s+/g, "-"))
                .join(" ")}`}
            >
              <div className="item archive-cards">
                <div className="img">
                  {!imageLoaded[i] && (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={483}
                      height={338}
                    />
                  )}
                  <img
                    loading="lazy"
                    src={item.img}
                    alt={item.title}
                    width="485"
                    height="auto"
                    style={{ display: imageLoaded[i] ? "block" : "block" }}
                    onLoad={() => handleImageLoad(i)}
                  />
                  {imageLoaded[i] && (
                    <h5
                      className="fz-12"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        width: "100%",
                        color: "snow",
                        background:
                          "linear-gradient(0deg, rgb(4 4 4 / 80%),  transparent)",
                        padding: "8px 4px 1rem",
                        textShadow: "0 0 4px #000",
                      }}
                    >
                      {item.title}
                    </h5>
                  )}
                  <div className="cont d-flex align-items-center archive-card-label">
                    <div>
                      <h5 className="fz-18">
                        <Link to={item.link} aria-label={item.title}>
                          {item.title}
                        </Link>
                      </h5>
                      <p className="fz-11 main-color">
                        <Link
                          to={item.link}
                          aria-label={item.categories.join(", ")}
                        >
                          {item.categories.join(", ")}
                        </Link>
                      </p>
                    </div>
                    <div className="ml-auto">
                      <Link
                        to={item.link}
                        className="ti-arrow-top-right"
                        aria-label="Go to link"
                      ></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="hz-lottie-container" style={{ userSelect: "none" }}>
        <Lottie
          id="hz-logo-animation"
          animationData={HZLogoAnimation}
          loop={false}
          style={{ display: "inline-block", userSelect: "none", zIndex: "0", height: "43px", width:"auto" }}
        />
      </div>
    </section>
  );
}

export default Portfolio;

console.clear();