"use client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingScreen from "../common/loader";
import ProgressScroll from "../components/default/ProgressScroll";
import Header from "../components/default/Header";
import Blog from "../components/default/Blog";
import Brands from "../components/default/Brands";
import Footer from "../components/default/Footer";
import Intro from "../components/default/Intro";
import Marquee from "../components/default/Marquee";
import Number from "../components/default/Number";
import StartSection from "../components/default/StartSection";
import Portfolio from "../components/default/Portfolio";
import Services2 from "../components/default/Services2";
import Testimonials from "../components/default/Testimonials";
import Clients from "../components/default/Clients";
//import Lottie from "lottie-react";
//import HZLogoAnimation from "../components/animation/HZ-Logo-Lottie.json";

export default function Home() {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  console.clear();

  return (
    <>
      <Helmet>
        <title>Archives | Overview</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <ProgressScroll />
        <LoadingScreen />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <Header />
              <Intro />
              <Number />
              <StartSection />
              <Marquee />
              <Brands />
              <Services2 />
              <Portfolio />
              <Testimonials />
              <Clients />
              <Blog />
            </main>
            <Footer />
            {/* <div className="hz-lottie-container">
              <Lottie
                id="hz-logo-animation"
                animationData={HZLogoAnimation}
                loop={false}
                style={{
                  position: "fixed",
                }}
              />
            </div> */}
          </div>
        </div>
      </body>
    </>
  );
}

console.clear();
