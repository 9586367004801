"use client";
import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { Link } from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";
import startSectionData from "../../data/Landing/startsection.json";

gsap.registerPlugin(ScrollTrigger);

function StartSection() {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(startSectionData);

    if (isDesktop) {
      let panels = gsap.utils.toArray(".panel");

      const container = document.querySelector(".theHcontainer");

      if (container) {
        const containerWidth = container.offsetWidth;

        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: ".showcases",
            pin: true,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            end: () => `+=${containerWidth}`,
          },
        });
      } else {
        console.warn(
          "The horizontal scroll container '.theHcontainer' is not found."
        );
      }
    }
  }, [isDesktop]);

  return (
    <section className="works showcases thecontainer theHcontainer sub-bg">
      <div className="text showcase-text-wrapper">
        <h2 className="text-bg showcase-text">Archive</h2>
      </div>
      {sections.map((item) => (
        <div className="panel" key={item.id}>
          <div className="item">
            <p className="mb-5">{item.text1}</p>
            <div
              className="img"
              style={{
                overflow: "hidden",
              }}
            >
              {item.video ? (
                <video
                  src={item.video}
                  height="auto"
                  width="100%"
                  autoplay="true"
                  loop="true"
                  muted="true"
                  preload="none"
                  onError={(e) =>
                    console.error("Error loading video:", e.target.src)
                  }
                />
              ) : (
                <img src={item.img} alt="" />
              )}
            </div>
            <div className="text-center">
              <p className="snow">{item.text2}</p>
            </div>
            {/* <Link to={item.link} className="plink">
              {item.text1}
            </Link> */}
          </div>
        </div>
      ))}
    </section>
  );
}

export default StartSection;
