"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import projectData from "../../../../data/projectarchives/countersec.json";

function Header() {
  const { headerData } = projectData;

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding pb-120"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">{headerData.category}</h6>
              <h3 className="mb-10">{headerData.project_title}</h3>
              <p>{headerData.content}</p>
              <h6 className="mt-30 main-color">{headerData.project_link}</h6>
              <a
                href="/archive"
                target="_blank"
                rel="noreferrer"
                style={{ letterSpacing: "1px" }}
              >
                {headerData.project_url}
              </a>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>{headerData.category}</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>{headerData.client}</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Year :</span>
                    <h6>{headerData.year}</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company :</span>
                    <h6>
                      {headerData.company}
                      <br />
                      <p className=" fz-12 fw-500 mt-5">
                        - {headerData.position}
                      </p>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid rest">
        <div
          className="img"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "white",
            
          }}
        >
          <div className="video-frame rest">
            <video
              id="tab-5"
              className="bg-img tab-img img-maxh1 rest"
              src={headerData.video.src}
              autoPlay
              loop
              muted
              width="100%"
              style={{
                maxWidth: "500px",
                width: "100%",
                height: "auto",
                backdropFilter: "brightness(1.3)",
                mixBlendMode: "darken",
              }}
            />
          </div>
        </div>

        <div className="container-fluid rest bg-black">
          <div className="col-md-12">
            <div className="text-center mb-30">
              <p className="white-bg text-black" style={{ padding: "1rem" }}>
                {headerData.imgcaption}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid rest mb-120">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30">
            <p>{headerData.sectitle}</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
        >
          {headerData.slides.map((slide, index) => (
            <SwiperSlide
              className="slider-video"
              key={index}
              style={{ marginRight: "0" }}
            >
              <div className="slide-video-wrapper">
                <video
                  className="video"
                  loading="lazy"
                  src={slide}
                  alt={`Slide ${index + 1}`}
                  autoPlay
                  loop
                  muted
                  width="100%"
                  height="auto"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
    </section>
  );
}

export default Header;
